import React from 'react';

import Layout from "../components/layout";
import {Helmet} from "react-helmet";
const ThankyouPage = () => (
     
   	<main className="w-full flex flex-col items-center">
        <div className="max-w-5xl mx-4">
        	<div className="flex flex-col mx-4 my-16 pt-8 relative">
                <img class="w-30 items-center mx-auto" src={require('../images/product/logo.png')} title="Cymetrix | CalendarInsight Salesforce App" alt="CalendarInsight Salesforce App by Cymetrix Software"/>        
            </div>
        	 <div className="max-w-6xl mx-4 my-16">
                <h1 className="text-black text-4xl text-center sma-xl font-bold">THANK YOU !</h1>
                 <p className="text-black text-2xl md:text-3xl sm:text-2xl text-center sma-xl font-thin mt-6"> We've received and noted your response. We will be getting back to you soonest.
</p>   
              
               <div class="place-content-center">
               <form>
                  <div className="-mx-3 md:flex mt-2 justify-center">
                     <div className="sma:w-50 items-center">
                        <button className="sma:w-50 bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" onclick={()=>{windows.location.href = 'https://www.cymetrixsoft.com/'}}>
                BACK TO HOMEPAGE
                         </button>
                    </div>
               </div>
               </form>
               </div>
             </div>
        </div>
    </main>
);

export default ThankyouPage;